export const SECTIONS: Map<string, any> = new Map([
  [
    'header',
    (): Promise<unknown> =>
      import('../shared/components/navigation/navigation.component').then((m) => m.NavigationComponent)
  ],
  [
    'footer',
    (): Promise<unknown> => import('../shared/components/footer/footer.component').then((m) => m.FooterComponent)
  ],

  [
    'heroSection',
    (): Promise<unknown> =>
      import('../shared/components/hero-section/hero-section.component').then((m) => m.HeroSectionComponent)
  ],
  [
    'cta',
    (): Promise<unknown> =>
      import('../shared/components/contact-cta/contact-cta.component').then((m) => m.ContactCtaComponent)
  ],
  // [
  //   'teamTile',
  //   (): Promise<unknown> =>
  //     import('../shared/components/tile-team-member/tile-team-member.component').then((m) => m.TileTeamMemberComponent)
  // ],
  [
    'tileList',
    (): Promise<unknown> =>
      import('../shared/components/tile-list/tile-list.component').then((m) => m.TileListComponent)
  ],

  [
    'localReference',
    (): Promise<unknown> =>
      import('../shared/components/local-reference/local-reference.component').then((m) => m.LocalReferenceComponent)
  ],
  [
    'serviceSectionImage',
    (): Promise<unknown> =>
      import('../shared/components/service-section-image-select/service-section-image-select.component').then(
        (m) => m.ServiceSectionImageSelectComponent
      )
  ],
  [
    'realEstateProject',
    (): Promise<unknown> =>
      import('../shared/components/real-estate-project/real-estate-project.component').then(
        (m) => m.RealEstateProjectComponent
      )
  ]
]);
